declare global {
  interface Window {
    _theme?: string;
    _page?: string;
  }
}

function init() {
  const jqueryScript = document.createElement('script');

  jqueryScript.src = "//cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"

  jqueryScript.addEventListener('load', function () {
    $.getScript('//cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.js', function () {
      const slick = $('.gallery__images').slick({
        infinite: true,
        dots: false,
        arrows: false,
        swipe: false,
        slidesToShow: window._theme === 'minimalist' ? 4 : 4,
        variableWidth: window._theme === 'minimalist',
        slidesToScroll: 1,
        initialSlide: 0,
        speed: window._theme === 'minimalist' ? 100 : 600,
        rtl: window._theme === 'minimalist',
        responsive: [
          {
            breakpoint: 769,
            settings: {
              slidesToShow: window._theme === 'minimalist' ? 2 : 3,
            },
          },
          {
            breakpoint: 641,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      });

      $('.gallery__dot').each((index, _element) => {
        const element = $(_element);
        element.on('click', () => {
          slick.slick('slickGoTo', index);
          $('.gallery__dot').removeClass('gallery__dot--active');
          element.addClass('gallery__dot--active');
        });
      });

      $('.slider-next').on('click', () => {
        slick.slick('slickNext');
      });

      $('.slider-previous').on('click', () => {
        slick.slick('slickPrev');
      });

      slick.on('afterChange', (_, __, index) => {
        $('.gallery__dot').removeClass('gallery__dot--active');
        $('.gallery__dot').get(index).classList.add('gallery__dot--active');
      });

      $.getScript(
        '//cdnjs.cloudflare.com/ajax/libs/slick-lightbox/0.2.12/slick-lightbox.min.js',
        function () {
          // @ts-ignore
          slick.slickLightbox({
            src: 'lightboxSrc',
            itemSelector: '.gallery__item img.gallery__image',
            caption: function (element) {
              return $(element).attr('alt');
            },
          });
        },
      );
    });

    $('.gallery__overlay').on('click', function () {
      $(this).parent().find('img.gallery__image').trigger('click');
    });

    function toggleNavigation() {
      const imagesCount = $('.gallery__item').length;
      if (window.innerWidth > 769 && imagesCount === 4) {
        $('.gallery__navigation').css('display', 'none');
      } else {
        $('.gallery__navigation').css('display', 'flex');
      }
    }
    window.addEventListener('resize', toggleNavigation);
    toggleNavigation();
  })

  document.body.appendChild(jqueryScript)
}

export default {
  init,
};
