
import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";

export const Setup = {
  onLoad:(callback: () => void) => {
    Rails.start()
    Turbolinks.start()
    ActiveStorage.start()

    document.addEventListener('turbolinks:load', () => {
      callback()
    });
  }
}