function init() {
  const container = document.querySelectorAll('.js-portrait-iframe-container');

  container.forEach((item) => {
    const { height, width } = item.getBoundingClientRect() || {};
    if (height != null && width != null) {
      const defaultVideoRatio = 16 / 9;
      const ratio = width / height;
      const iframe = item.querySelector<HTMLIFrameElement>('.js-portrait-iframe');
      if (defaultVideoRatio > ratio && iframe != null) {
        iframe.style.transform = `translateY(-50%) scale(${height / (width / defaultVideoRatio)})`;
      } else if (iframe != null) {
        iframe.style.transform = `translateY(-50%) scale(${ratio / defaultVideoRatio})`;
      }
    }
  });
}

export default {
  init,
};
