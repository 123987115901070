function init() {
  const mapBoxScript = document.createElement('script');

  mapBoxScript.src = 'https://api.tiles.mapbox.com/mapbox-gl-js/v2.2.0/mapbox-gl.js'

  mapBoxScript.addEventListener('load', function () {
    mapboxgl.accessToken =
      'pk.eyJ1IjoiZHJhd2JvdGljcy10ZWFtIiwiYSI6ImNqbTh0MDdiazBydjQzcW50eGRyMTZkZTkifQ.h2eBUSc3FaI2cbOMTxvzaA';

    const coordinates: mapboxgl.LngLatLike = JSON.parse(document.querySelector('#map').getAttribute('data-coordinates'));

    const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/light-v9',
      center: coordinates,
      zoom: 12,
    });

    const featureLayer = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates,
          },
          properties: {
            title: 'Mapbox',
            description: 'Washington, D.C.',
          },
        },
      ],
    };

    featureLayer.features.forEach((marker) => {
      // create a HTML element for each feature
      const el = document.createElement('div');
      el.className = 'footer__marker';

      const img = document.createElement('img');
      img.src = document.querySelector('#map').getAttribute('data-logo');
      el.append(img);

      // make a marker for each feature and add to the map
      new mapboxgl.Marker(el, {
        anchor: 'bottom',
      })
        .setLngLat(marker.geometry.coordinates)
        .addTo(map);
    });
  })

  document.body.appendChild(mapBoxScript)
}

export default {
  init,
};
