function init() {
  if (window._page === 'privacy_policy') {
    document.querySelector('.navigation').classList.add('navigation--grey');
  }
  const menu = document.querySelector('.navigation__item--hidden');

  function handleToggle() {
    const open = $('.navigationModal').hasClass('navigationModal--visible');
    if (open) {
      menu.querySelector('i').classList.remove('Drycon-x');
      menu.querySelector('i').classList.add('Drycon-menu');
      menu.querySelector('.navigationModal').classList.remove('navigationModal--visible');
    } else {
      menu.querySelector('i').classList.remove('Drycon-menu');
      menu.querySelector('i').classList.add('Drycon-x');
      menu.querySelector('.navigationModal').classList.add('navigationModal--visible');
    }
  }

  menu.addEventListener('click', () => {
    handleToggle();
  });

  document.querySelector('.navigationModal__item').addEventListener('click', () => {
    handleToggle();
  });

  window.addEventListener('scroll', function () {
    if (window._theme !== 'minimalist') {
      return;
    }

    if (this.window.scrollY > 0) {
      document.querySelector('.js-navigation-onscroll').classList.add('navigation--grey');
    } else {
      document.querySelector('.js-navigation-onscroll').classList.remove('navigation--grey');
    }
  });
}

export default {
  init,
};
