function init() {
  document.querySelectorAll('[data-to]').forEach((element) => {
    element.addEventListener('click', () => {
      const to = element.getAttribute('data-to');
      const target = document.querySelector(to);
      window.scrollTo({
        top: target.getBoundingClientRect().top + window.pageYOffset - 100,
        behavior: 'smooth',
      });
    });
  });
}

export default {
  init,
};
