function init() {
  const width = document.getElementsByClassName('main__container')[0].getBoundingClientRect().width;
  //Following values are fixed for our revos
  const revoWidth = 1920;
  const revoHeight = 1080;
  const sidebarWidth = window.innerWidth > 768 ? 60 : 0;
  document.querySelector<HTMLElement>('.main__container').style.height = `${width / ((revoWidth + sidebarWidth) / revoHeight)
    }px`;
}

export default {
  init,
};
