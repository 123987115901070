import { Setup } from './shared/setup'

import ScrollTo from './website_builder/scroll-to';
import Gallery from './website_builder/gallery';
import Map from './website_builder/map';
import Navigation from './website_builder/navigation';
import PotraitVideo from './website_builder/portrait-video'
import Main from './website_builder/main';

Setup.onLoad(() => {
  ScrollTo.init();
  Gallery.init();
  Map.init();
  Navigation.init();
  PotraitVideo.init();
  Main.init();
})
